/**
* Generated automatically at built-time (2024-11-05T11:44:17.559Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "bouwen-buitenkeukens",templateKey: "sites/107-7b39fd8e-8a45-4ea2-bdc5-3406a9b988ea"};