/**
* Generated automatically at built-time (2024-11-05T11:44:17.613Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "induplus",templateKey: "sites/107-84d27814-7b9b-4e28-90aa-8a98d7f00804"};